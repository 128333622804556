//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-760:_216,_4434,_3760,_3010,_5388,_6496,_6400,_3528;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-760')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-760', "_216,_4434,_3760,_3010,_5388,_6496,_6400,_3528");
        }
      }catch (ex) {
        console.error(ex);
      }